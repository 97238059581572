import { PROFILE } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { profileservice } from "../../services/profile.service";

export const profileActions = {
    getProfile,
    updateProfile,
    isSellingPrice
};

export function getProfile(id: any, userType: string) {
    return async (dispatch: any) => {
        // dispatch(apiStart(PROFILE.GET_PROFILE_FETCHING));
        try {
            const response = await profileservice.getProfile(id, userType);
            dispatch(apiSuccess(PROFILE.GET_PROFILE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.GET_PROFILE_ERROR, error));
            return error;
        }
    };
}

export function updateProfile(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(PROFILE.PUT_PROFILE_FETCHING));
        try {
            const response = await profileservice.updateProfile(data);
            dispatch(apiSuccess(PROFILE.PUT_PROFILE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.PUT_PROFILE_ERROR, error));
            return error;
        }
    };
}

export function isSellingPrice(data: any) {
    return async (dispatch: any) => {
      dispatch(apiSuccess(PROFILE.IS_SELLING_PRICE_TOGGLE_BUTTON, data));
      return data || {};
    };
  }