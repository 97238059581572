import _ from 'lodash';

export function getIP() {
    fetch('https://api.ipify.org?format=json').then(response => {
        return response.json();
    }).then((res: any) => {
        let myIp = _.get(res, 'ip');
        sessionStorage.setItem('clientIp', myIp);
    }).catch((err: any) => console.error('Problem fetching my IP', err))
}

export function getNewBrand(brand: any) {
    let newBrand = '';
    if (brand) {
        newBrand = brand.toUpperCase();
        switch (newBrand) {
            case "TOYOTA": {
                return 'Al Futtaim Motors';
            }
            case "TE": {
                return "Trading Enterprise"
            }
            default:
                return brand;
        }
    } else {
        return brand;
    }
}

export function getActualBrand(brand: any) {
    let newBrand = '';
    if (brand) {
        newBrand = brand.toUpperCase();
        switch (newBrand) {
            case "AL FUTTAIM MOTORS": {
                return 'Toyota';
            }
            case "TRADING ENTERPRISE": {
                return "TE"
            }
            case "TRADING ENTERPRISES": {
                return "TE"
            }
            default:
                return brand;
        }
    } else {
        return brand;
    }
}

export function getUniqueTimestamp() {
    let date = new Date().valueOf();
    return date;
}
