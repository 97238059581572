import React from "react";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: sessionStorage.getItem("jwt-token") ? true : false
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
   // throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// ###########################################################

function loginUser(dispatch) {

  // Store state and nonce parameters into the session, so we can retrieve them after
  // user will be redirected back with access token or code (since react state is cleared in this case)
  sessionStorage.removeItem("isAuthenticated");
  sessionStorage.removeItem("access-token");
  sessionStorage.removeItem("jwt-token");
  sessionStorage.removeItem("oAuth");
  sessionStorage.removeItem("refreshTokenExpiresOn");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessTokenExpiresOn");
  sessionStorage.removeItem("customerName");
  sessionStorage.removeItem("customerId");
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("userId");
  sessionStorage.removeItem("kamOrgs");
  // sessionStorage.removeItem("sessionExpiry");
  sessionStorage.removeItem("restrictFeat");
  sessionStorage.removeItem("brands");
  sessionStorage.removeItem("enquiryModeDetails");
  sessionStorage.removeItem("webApplicationLoginId");
  sessionStorage.removeItem("partsData");
  sessionStorage.removeItem("clientIp");
  sessionStorage.removeItem('userType');
  // sessionStorage.clear();
  dispatch({ type: "LOGIN_SUCCESS" });
}

function signOut(dispatch) {
  sessionStorage.removeItem("isAuthenticated");
  sessionStorage.removeItem("access-token");
  sessionStorage.removeItem("jwt-token");
  sessionStorage.removeItem("oAuth");
  sessionStorage.removeItem("refreshTokenExpiresOn");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessTokenExpiresOn");
  sessionStorage.removeItem("customerName");
  sessionStorage.removeItem("customerId");
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("userId");
  sessionStorage.removeItem("kamOrgs");
  // sessionStorage.removeItem("sessionExpiry");
  sessionStorage.removeItem("restrictFeat");
  sessionStorage.removeItem("brands");
  sessionStorage.removeItem("enquiryModeDetails");
  sessionStorage.removeItem("webApplicationLoginId");
  sessionStorage.removeItem("partsData");
  sessionStorage.removeItem("clientIp");
  sessionStorage.removeItem('userType');
  // sessionStorage.clear();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

