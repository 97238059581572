import { DEALER } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { dealerService } from "../../services/dealer.service";

export const dealerActions = {
  createDealer,
  createDealerUser,
  getAllDealers,
  updateDealerInProgressUser,
  getDealerDetails,
  checkUserIdAvailability,
  updateDealer,
  updateDealerUser,
  verifySAPCustomerId,
  getAccessParam,
  addAccessParam,
  updateAccessParam,
  sendEmail,
  getkamUserList,
  getDealerCreditRequest,
  saveAdditionalCreditRequest,
  deleteBankStatementOfCreditRequest,
  generateB2bUserId,
  sendInvitationLink,
  confirmDataFromDealer
};

export function createDealer(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_DEALER_FETCHING));
    try {
      const response = await dealerService.addDealer(data);
      dispatch(apiSuccess(DEALER.POST_DEALER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_DEALER_ERROR, error));
      return error;
    }
  };
}

export function createDealerUser(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_DEALER_USER_FETCHING));
    try {
      const response = await dealerService.addDealerUser(data);
      dispatch(apiSuccess(DEALER.POST_DEALER_USER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_DEALER_USER_ERROR, error));
      return error;
    }
  };
}

export function getAllDealers(filterBy?: string, searchBy?: string, page?: number) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_DEALERS_FETCHING));
    try {
      const response = await dealerService.getAllDealers(filterBy, searchBy, page);
      dispatch(apiSuccess(DEALER.GET_ALL_DEALERS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ALL_DEALERS_ERROR, error));
      return error;
    }
  };
}


export function getAllDealersRequests(loginId?: string, searchBy?: string, filterValue?: any, page?: number) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_DEALER_REQUESTS_FETCHING));
    try {
      const response = await dealerService.getAllDealersRequests(loginId, searchBy, filterValue, page);
      dispatch(apiSuccess(DEALER.GET_ALL_DEALER_REQUESTS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ALL_DEALER_REQUESTS_ERROR, error));
      return error;
    }
  };
}

export function getDealerDetails(customerId: string, type: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_DEALER_DETAILS_FETCHING));
    try {
      const response = await dealerService.getDealerDetails(customerId, type);
      dispatch(apiSuccess(DEALER.GET_DEALER_DETAILS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_DEALER_DETAILS_ERROR, error));
      return error;
    }
  };
}

export function checkUserIdAvailability(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.CHECK_USER_AVAILABILITY_FETCHING));
    try {
      const response = await dealerService.checkUserIdAvailability(data);
      dispatch(apiSuccess(DEALER.CHECK_USER_AVAILABILITY_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.CHECK_USER_AVAILABILITY_ERROR, error));
      return error;
    }
  };
}

export function updateDealer(customerId: string, data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_DEALER_FETCHING));
    try {
      const response = await dealerService.updateDealer(customerId, data);
      dispatch(apiSuccess(DEALER.PUT_DEALER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_DEALER_ERROR, error));
      return error;
    }
  };
}

export function updateDealerUser(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_DEALER_USER_FETCHING));
    try {
      const response = await dealerService.updateDealerUser(data);
      dispatch(apiSuccess(DEALER.PUT_DEALER_USER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_DEALER_USER_ERROR, error));
      return error;
    }
  };
}

export function updateDealerInProgressUser(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_DEALER_USER_IN_PROGRESS_FETCHING));
    try {
      const response = await dealerService.updateDealerInProgressUser(data);
      dispatch(apiSuccess(DEALER.PUT_DEALER_USER_IN_PROGRESS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_DEALER_USER_IN_PROGRESS_ERROR, error));
      return error;
    }
  };
}

export function getAllUsers(id?: any, page?: number, searchBy?: string) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_USER_LIST_FETCHING));
    const response = await dealerService.getAllUsers(id, page, searchBy);
    if (response.error) {
      dispatch(apiError(DEALER.GET_USER_LIST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_USER_LIST_SUCCESS, response));
      return response || [];
    }
  }
}

export function verifySAPCustomerId(sapCustomerId: string) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.VERIFY_SAPCUSTOMERID_FETCHING));
    try {
      const response = await dealerService.verifySAPCustomerId(sapCustomerId);
      dispatch(apiSuccess(DEALER.VERIFY_SAPCUSTOMERID_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.VERIFY_SAPCUSTOMERID_ERROR, error));
      return error;
    }
  };
}

export function setUserDetails(user: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(DEALER.SET_USER_DETAILS, user));
    return user || {};
  };
}

export function getUserDetails(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_USER_DETAILS_FETCHING));
    const response = await dealerService.getUserDetailsInfo(b2bUserId);
    if (response.error) {
      dispatch(apiError(DEALER.GET_USER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_USER_DETAILS_SUCCESS, response));
      return response || [];
    }
  }
}

export function storeBrandValues(values: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(DEALER.STORE_BRAND_VALUES, values));
    return values || {};
  };
}

export function getUserParameters(sapCustomerId: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_USER_PARAMETER_FETCHING));
    const response = await dealerService.getUserParameter(sapCustomerId, b2bUserId);
    if (response.error) {
      dispatch(apiError(DEALER.GET_USER_PARAMETER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_USER_PARAMETER_SUCCESS, response));
      return response || [];
    }
  }
}

export function resendInvitationLink(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.RESEND_INVITATION_LINK_FETCHING));
    const response = await dealerService.resendInvitationLink(b2bUserId);
    if (response.error) {
      dispatch(apiError(DEALER.RESEND_INVITATION_LINK_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.RESEND_INVITATION_LINK_SUCCESS, response));
      return response || [];
    }
  }
}

export function addUserParameters(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.ADD_USER_PARAMETER_FETCHING));
    const response = await dealerService.addUserParameter(data);
    if (response.error) {
      dispatch(apiError(DEALER.ADD_USER_PARAMETER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.ADD_USER_PARAMETER_SUCCESS, response));
      return response || [];
    }
  }
}

export function editUserParameters(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.EDIT_USER_PARAMETER_FETCHING));
    const response = await dealerService.editUserParameter(data);
    if (response.error) {
      dispatch(apiError(DEALER.EDIT_USER_PARAMETER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.EDIT_USER_PARAMETER_SUCCESS, response));
      return response || [];
    }
  }
}

export function getAccessParam(id: any, adminId: any, action: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.getAccessParam(id, adminId, action);
      dispatch(apiSuccess(DEALER.GET_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function getKamAccessParam(id: any, sapId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.getKamAccessParam(id, sapId);
      dispatch(apiSuccess(DEALER.GET_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function addAccessParam(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.addAccessParam(data);
      dispatch(apiSuccess(DEALER.POST_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function updateAccessParam(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.updateAccessParam(data);
      dispatch(apiSuccess(DEALER.PUT_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function uploadDocument(data: any, customHeaders: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_UPLOAD_DOCUMENT_FETCHING));
    try {
      const response = await dealerService.uploadDocument(data, customHeaders);
      dispatch(apiSuccess(DEALER.POST_UPLOAD_DOCUMENT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_UPLOAD_DOCUMENT_ERROR, error));
      return error;
    }
  };
}

export function deleteDocument(id: any, sapCustomerId: any, document: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.DELETE_DOCUMENT_FETCHING));
    try {
      const response = await dealerService.deleteDocument(id, sapCustomerId, document);
      dispatch(apiSuccess(DEALER.DELETE_DOCUMENT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.DELETE_DOCUMENT_ERROR, error));
      return error;
    }
  };
}

export function getUploadedDocuments(customHeaders: any, sapCustomerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.Get_UPLOADED_DOCUMENT_FETCHING));
    try {
      const response = await dealerService.getUploadedDocuments(customHeaders, sapCustomerId);
      dispatch(apiSuccess(DEALER.Get_UPLOADED_DOCUMENT_SUCCESS, response.data));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.Get_UPLOADED_DOCUMENT_ERROR, error));
      return error;
    }
  };
}

export function sendEmail(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SEND_EMAIL_FETCHING));
    const response = await dealerService.sendEmailTemplate(data);
    if (response.error) {
      dispatch(apiError(DEALER.SEND_EMAIL_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.SEND_EMAIL_SUCCESS, response));
      return response || [];
    }
  }
}

export function getkamUserList(data?:any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_KAMUSER_FETCHING));
      try {
        const response = await dealerService.getKAMUserList(data);
        dispatch(apiSuccess(DEALER.GET_KAMUSER_SUCCESS, response));
        return response || [];
      } catch (error) {
        dispatch(apiError(DEALER.GET_KAMUSER_ERROR, error));
        return error;
      }
    };
  }

  export function getAllSapCustomerIds() {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_SAP_CUSTOMER_IDS_FETCHING));
      const response = await dealerService.getAllSapCustomerId();
      if (response.error) {
        dispatch(apiError(DEALER.GET_SAP_CUSTOMER_IDS_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GET_SAP_CUSTOMER_IDS_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getAllLostSaleReasons() {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_LOST_SALE_REASON_FETCHING));
      const response = await dealerService.getAllLostSaleReason();
      if (response.error) {
        dispatch(apiError(DEALER.GET_LOST_SALE_REASON_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GET_LOST_SALE_REASON_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getAllBrands() {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_ALL_BRANDS_FETCHING));
      const response = await dealerService.getAllBrand();
      if (response.error) {
        dispatch(apiError(DEALER.GET_ALL_BRANDS_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GET_ALL_BRANDS_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getAllCreditRequestStatus(type: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_ALL_CR_FETCHING));
      const response = await dealerService.getAllCRStatus(type);
      if (response.error) {
        dispatch(apiError(DEALER.GET_ALL_CR_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GET_ALL_CR_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getLostSaleReport(data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_LOST_SALE_REPORT_FETCHING));
      const response = await dealerService.getLostSaleReportData(data);
      if (response.error) {
        dispatch(apiError(DEALER.GET_LOST_SALE_REPORT_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GET_LOST_SALE_REPORT_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function resetReportData() {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.RESET_REPORT_DATA));
    };
  }

  export function selectedNewDealer(item: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.SET_NEW_DEALER_DATA, item));
    };
  }

  export function isEdit(value: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.IS_EDIT, value));
    };
  }

  export function getDealerCreditRequest(id: any, sapCustomerId: any, loginId: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GET_DEALER_CREDIT_REQUEST_FETCHING));
      const response = await dealerService.getDealerCreditRequest(id, sapCustomerId, loginId);
      if (response.error) {
        dispatch(apiError(DEALER.GET_DEALER_CREDIT_REQUEST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GET_DEALER_CREDIT_REQUEST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function saveAdditionalCreditRequest(sapCustomerId: any,data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_FETCHING));
    const response = await dealerService.saveAdditionalCreditRequest(sapCustomerId,data);
      if (response.error) {
        dispatch(apiError(DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function deleteBankStatementOfCreditRequest(documentId: any, data: any, requestId: any, ) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_FETCHING));
    const response = await dealerService.deleteBankStatementOfCreditRequest(documentId, requestId, data);
      if (response.error) {
        dispatch(apiError(DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function confirmDataFromDealer(data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.CONFIRM_DATA_FROM_DEALER_FETCHING));
      const response = await dealerService.confirmDataFromDealer(data);
      if (response.error) {
        dispatch(apiError(DEALER.CONFIRM_DATA_FROM_DEALER_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.CONFIRM_DATA_FROM_DEALER_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function sendInvitationLink() {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.SEND_INVITATION_LINK_FETCHING));
      const response = await dealerService.sendInvitationLink();
      if (response.error) {
        dispatch(apiError(DEALER.SEND_INVITATION_LINK_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.SEND_INVITATION_LINK_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function generateB2bUserId(data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(DEALER.GENERATE_B2B_USER_ID_FETCHING));
      const response = await dealerService.generateB2bUserId(data);
      if (response.error) {
        dispatch(apiError(DEALER.GENERATE_B2B_USER_ID_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DEALER.GENERATE_B2B_USER_ID_SUCCESS, response));
        return response || [];
      }
    }
  }

export function viewCreditDealer(id:any, year: any){
   return async (dispatch:any)=>{
     dispatch(apiStart(DEALER.GET_VIEWCREDIT_DEALER_FETCHING));
     const response = await dealerService.getViewTarget(id, year);
     console.log("response-view-credit",response)
     if (response.error){
       dispatch(apiError(DEALER.GET_VIEWCREDIT_DEALER_ERROR,response))
       return response || []
     }else {
       dispatch(apiSuccess(DEALER.GET_VIEWCREDIT_DEALER_SUCCESS,response))
       return response || []
     }
   } 
}  